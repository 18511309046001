import React, { Component } from 'react';

import './Streams.css'
import { Conversation } from '../App';
import Video from './Video';

class Streams extends Component {
  liveContacts = [];

  state = {
    big: 'local',
    zoomed: false,
    stats: {}
  }

  setBigScreen(kind) {
    this.props.bigScreenChanged(kind);
    this.setState({big: kind});
  }

  getStreamsOnline() {
    return this.props.remotes.map(r => r.remoteStream).concat([this.props.localStream]).filter(s => {
      return s && s.active && s.getTracks().some(t => t.readyState === 'live');
    });
  }

  toggleZoom() {
    this.setState({
      zoomed: !this.state.zoomed
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const onlineStreams = this.getStreamsOnline();

    if (this.props.callStatus !== 'established' && onlineStreams.length > 1) {
      this.props.onStreamsRunning();
      }

    let mainScreen = this.state.big;
    const liveContacts = this.props.remotes.filter(r => r.remoteStream && r.pc && r.remoteStream.active && r.remoteStream.getTracks().some(t => t.readyState === 'live'));

    if (mainScreen !== 'local' && !liveContacts.find(s => s.id === mainScreen)) {
      // mainScreen points to an invalid stream
      if (liveContacts.length === 1) {
        mainScreen = liveContacts[0].id;
      }
      else if (liveContacts.length === 0) {
        mainScreen = 'local';
      }

      if (mainScreen !== this.state.big) {
        this.setBigScreen(mainScreen);
      }
    }
    else if (mainScreen === 'local' && liveContacts.length === 1) {
      if (this.liveContacts.length === 0) {
        mainScreen = liveContacts[0].id;

        if (mainScreen != this.state.big) {
          this.setBigScreen(mainScreen);
        }
      }
    }

    this.liveContacts = liveContacts;
  }

  render() {
    let mainScreen = this.state.big;
    const liveStreams = this.props.remotes.filter(r => r.remoteStream && r.pc && r.remoteStream.active && r.remoteStream.getTracks().some(t => t.readyState === 'live'));
    const streams = liveStreams.map(r => {
      const isBigStream = (mainScreen === r.id);
      return (
        <div
          key={r.id}
          className={"remoteStreamContainer" + (isBigStream ? ' bigStream' : ' smallStream')}
          onClick={() => mainScreen === r.id ? this.toggleZoom() : this.setBigScreen(r.id)}
          style={isBigStream ? {} : { backgroundColor: 'black' }} >
          <div className="contactname"><span>{r.name}</span></div>
          <Video muted={true} data-id={r.id} className="remoteStream" speaker={this.props.currentSpeaker} stream={r.remoteStream} onError={() => this.props.onError('remote', r)} />
        </div>
      );
    });

    return (
      <div className={
          "streams"
          + (this.props.remoteStream ? ' hasremotestream' : '')
          + (this.props.localStream ? ' haslocalstream' : '')
          + (this.state.zoomed ? ' zoomed' : '')
        }>
          {streams}
          {mainScreen && this.props.localStream &&
            <div className={"localStreamContainer" + (mainScreen === 'local' ? ' bigStream' : ' smallStream')}
            onClick={() => mainScreen === 'local' ? this.toggleZoom() : this.setBigScreen('local')}
          >
            <div className="contactname"><span>{this.props.local.name}</span></div>
            <Video className="localStream" stream={this.props.localStream} muted={true} onError={() => this.props.onError('local')} />
          </div>}
      </div>
    )
  }
};

export default Streams; 
