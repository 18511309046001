import React, { Component } from 'react';

class Video extends Component {
  timeout = null;
  videoTrackCount = 0;

  shouldComponentUpdate(nextProps) {
    if (!this.refs.stream || !this.refs.stream.srcObject) return true;

    if (nextProps.stream && this.refs.stream.srcObject && nextProps.stream.id !== this.refs.stream.srcObject.id) {
      return true;
    }
    
    if (!nextProps.stream) {
      return true;
    }

    if (nextProps.stream.getVideoTracks().length !== this.videoTrackCount) {
      return true;
    }

    if (nextProps.stream.getVideoTracks()[0].id != this.refs.stream.srcObject.getVideoTracks()[0].id) return true;

    if (nextProps.muted !== this.props.muted) {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('muted', nextProps.muted);
      }
      return false;
    }

    return false;
  }

  componentDidMount() {
    this.refs.stream.addEventListener('playing', () => {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('data-orientation', this.refs.stream.videoWidth > this.refs.stream.videoHeight ? 'landscape' : 'portrait');
        this.refs.stream.setAttribute('data-width', this.refs.stream.videoWidth);
        this.refs.stream.setAttribute('data-height', this.refs.stream.videoHeight);
      }
    });
  }

  componentDidUpdate() {
    let nextProps = this.props;

    if (!this.props.stream) {
      clearTimeout(this.timeout);
      return;
    }

    let setStream = () => {
      this.refs.stream.srcObject = nextProps.stream;
      
      this.refs.stream.setAttribute('playsinline', true);
      this.refs.stream.setAttribute('autoplay', false);

      let tracks = nextProps.stream.getVideoTracks();
      this.videoTrackCount = tracks.length;
      let id = tracks.length ? tracks[0].id : 'no tracks';
      console.log(this.props.className, "Reading", id);

      const playPromise = this.refs.stream.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          console.log("Playing");
        })
        .catch(error => {
          console.log("ERROR", id, error);
        });
      }
      console.log("Added stream", nextProps.stream);
    }

    setStream();

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    const errorCheck = () => {
    this.timeout = setTimeout(() => {
      if (this.refs.stream && this.refs.stream.currentTime === 0) {
        // revert to stream before
        console.log("Not playing");
        this.props.onError();
          errorCheck();
      }
      }, 5000);
    };
    errorCheck();
  }

  render() {
    return (<video {...this.props} muted={this.props.muted} ref="stream" autoPlay playsInline></video>);
  }
};

export default Video;
